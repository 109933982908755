.home-page-content{
    height: 100vh;
    width: 100vw;
    background:#efefef;
    display: flex;
    flex-direction: column;
    .home-head{
        height: 68px;
        text-align: center;
        font-size: 14px;
        background: #008ffe;
        .top{
            height: 40px;
            line-height: 40px;
            color: #fff;
            span{
                font-size: 18px;
                display: inline-block;
                margin: 0 2px;
            }
        }

        .bottom{
            height: 20px;
            line-height: 18px;
            display: inline-block;
            margin: 0 2px;
            font-size: 14px;
            color: #fff;

        }


    }

    .home-page-list-content{
        flex: 1;
        overflow-y: auto;
    }
    .am-calendar {
        .header {
            .right{
                display: none;
            }
        }
    }
}
.nav-to-mymeetng{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #008ffe;
    position: absolute;
    bottom:40px ;
    right: 10px;
    border-radius: 50%;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    color: #efefef;
    z-index: 10;
    transition: all .1s linear;
}
.nav-to-mymeetng-dark{
    // background: RGBA(0, 0, 255, 1);
}
.nav-01{
    width: 35px;
    height: 35px;
    line-height: 35px;
    right: 13px;
    bottom:90px ;
}
.nav-02{
    width: 35px;
    height: 35px;
    line-height: 35px;
    right: 13px;
    bottom:135px;
}

.nav-03{
    width: 35px;
    height: 35px;
    line-height: 35px;
    right: 13px;
    bottom:180px;
}

