.detial-page-content{
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    .detial-page-img{
        width: 100vw;
        height: 220px;
        text-align: center;
        img{
            // width: 100%;
            // height: 100%;
        }
    }
    .detial-page-info{
        padding: 20px;
        h3{
            line-height: 30px;
            font-size: 22px;
            font-weight: 500;
        }
    }
    .detial-page-time{
        display: flex;
        text-align: center;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 22px;
        height: 45px;
        line-height: 45px;
        .detial-page-time-reduce{
            width: 40px;
            height: 45px;
            line-height: 45px;
        }
        .detial-page-time-center{
            flex: 1;
            font-size: 15px;
            font-weight: 600;
        }
        .detial-page-time-add{
            width: 40px;
            height: 45px;
            line-height: 45px;
        }
        .iconAdd{
            font-size: 22px;
            color: #888;
        }
        .active{
            .iconAdd{
                font-size: 22px;
                color: #000;
            }
        }
    }

    .detial-page-date{
        padding: 30px 15px;
        display: flex;
        h3{
            flex: 1;
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
        div{
            flex: 1;
            text-align: end;
            .iconrignt{
                font-size: 12px;
                margin-left: 10px;
            }
        }
    }

    .detial-page-place{
        padding: 5px 15px;
        h3{
            height: 40px;
            line-height: 40px;
            font-weight: 600;
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
        .detial-page-place-p{
            line-height: 20px;
            font-size: 14px;
            margin: 0;
            padding: 0;
            color: #999;
            height:auto;
            .float-icon-right{
                float: right;
                font-size: 12px;
            }
        }
        .detial-page-server{
            // height: 30px;
            // line-height: 30px;
            p{
                display: inline-block;
                margin: 0;
                padding: 0;
                height: 30px;
                line-height: 30px;
                padding:0px 30px;
                margin: 0 5px;
                border-radius: 10px;
                background: #D8D8D8;
                color: #fff;
                margin-top: 5px;
            }
            input{
                width: 92px;
                height: 30px;
                line-height: 30px;
                border: 0px;
                outline: none;
                margin: 0 5px;
                background: #fff;
                border-radius: 10px;
            }
        }
    }
    .detial-page-footer{
        padding:18px;
        background: #fff;
        .detial-page-footer-btn{
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            border-radius: 3px;
        }
    }
}
