.choice-person-content {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background:#E6E7E8;
    font-family: PingFangSC-Regular, sans-serif;
    font-size: 14px;
    color: #111111;
  }
  
  .choice-person-content .choice-persion-seach .am-search{
    background-color: #f5f5f5 ;
    height: 52px;
    padding: 0 12px;
  }
  .choice-person-content .choice-persion-seach .am-search .am-search-value input{
    height: 100%;
  }
  
  .choice-person-content .choice-persion-seach .am-search-cancel {
    font-size: 14px;
    color: #666;
    margin-left: 4px;
  }
  
  .choice-person-content .choice-person-item-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  
  .choice-person-content .select-path {
    width: 100vw;
    height: 34px;
    line-height: 34px;
    padding: 0 15px;
    background: #f5f5f5;
    border-bottom: 1px solid #eee;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .choice-person-content .select-path .select-path-content{
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  
  .choice-person-content .select-path .select-path-content > p {
    height: 34px;
    line-height: 34px;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: #111111;
    display: inline-block;
  }
  
  .choice-person-content .select-path .select-path-content > p > span {
    font-size: 12px;
    margin-right: 12px;
    color: #008FFE;
  }
  .choice-person-content .select-path .select-path-content .breadcrumb-symbol {
    margin-left: 12px;
    color: #ccc;
  }
  .choice-person-content .select-path .select-path-content .breadcrumb-symbol .iconfont {
    font-size: 10px;
  }
  
  .choice-person-content .choice-person-item-content .choice-person-items {
    background: #fff;
    margin-bottom: 10px;
  }
  .choice-person-content .choice-person-item-content .choice-person-items .choice-person-items {
    display: flex;
  }
  
  .choice-person-content .choice-person-item-content .choice-person-items .choice-item {
    padding:  12px;
    font-size: 16px;
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid #eee;
  }
  .choice-person-content .choice-person-item-content .choice-person-items .choice-item:last-child{
    border-bottom: none
  }
  .choice-person-content .choice-person-item-content .choice-person-items .lable-icon {
    height: 40px;
    width: 40px;
    margin-right: 15px;
    text-align: center;
    background: #F0F2F4;
    border-radius: 2px;
    line-height:40px;
    color:#81AFE8 ;
  }
  .choice-person-content .choice-person-item-content .choice-person-items .lable-name{
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    color: #111111;
  }
  
  .choice-person-content .choice-person-item-content .choice-person-items .icon {
    box-sizing: border-box;
    text-align: center;
    color: #999;
    padding-top: 10px;
  }
  .choice-person-content .choice-person-item-content .choice-person-items .icon svg {
    width: 15px;
  }
  
  .choice-person-content .choice-person-footer {
    height: 48px;
    background: #fff;
    box-sizing: border-box;
    padding-left: 10px;
    display: flex;
  }
  .choice-person-content .choice-person-footer .person-select-all{
     padding-right: 42px;
     height: 48px;
     line-height: 48px;
     color: rgb(79, 124, 244);
     position: relative;
  }
  .choice-person-content .choice-person-footer .person-select-all::after {
    position: absolute;
    content: '';
    background: #eee;
    height: 16px;
    width: 1px;
    right: 21px;
    top: 15px;
  }
  
  .choice-person-content .choice-person-footer .person-count {
    flex: 1;
    height: 48px;
    line-height: 48px;
    color: rgb(79, 124, 244);
  }
  
  .choice-person-content .choice-person-footer .person-action {
    width: 102px;
    height: 48px;
    line-height: 48px;
    background: #008FFE;
    font-family: 'PingFangSC-Regular';
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
  }