.my-reserve-content{
    width: 100vw;
    height: 100vh;
    background: rgb(245, 245, 245);
    padding: 12px;
    font-weight: bold;
    flex: 1;
    overflow-y: auto;
    .oneDay{
        padding-bottom: 18px;
        .date{
            font-size: 14px;
            color: #008ffe;
            padding-bottom: 12px;
            font-weight: 400;
        }
        .date1{
            font-size: 14px;
            color: #000000;
            padding-bottom: 12px;
        }
        .meeting{
            display: flex;
            background-color: #FFFFFF;
            border-radius: 4px;
            align-items: center;
            height: 64px;
            margin-bottom: 8px;
            box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
            .meeting-icon{
                width: 30px;
                height: 30px;
                margin: 15px;
                .icon{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 50%;
                    background:#008ffe;
                    color: #fff;
                    font-size: 16px;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                font-size: 12px;
                height: 46px;
                border-left: 1px solid #efefef;
                padding-left: 10px;
                font-weight:400;
                .top{
                    display: flex;
                    color: #000000;
                    .before{
                        width: 43px;
                        display: flex;
                        align-items: flex-end;
                    }
                    .after{
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        max-width: 220px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .bottom{
                    display: flex;
                    color: #888888;
                    .before{
                        width: 43px;
                    }
                    .after{
                        max-width: 220px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
        .none{
            height: 50px;
            padding-left: 16px;
        }
    }
}