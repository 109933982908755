
  .set-person-content{
    width: 100vw;
    height: 100vh;
    background: #E6E7E8;
    display: flex;
    flex-direction: column;
  }
  .set-person-content .set-person-header{
    height: 50px;
    line-height: 50px;
    background: #FFFFFF;
    font-family: 'PingFangSC-Regular';
    font-size: 14px;
    color: #111111;
    padding: 0 12px;
    border-bottom: .5px solid #EEEEEE;
    box-sizing: border-box;
    display: flex;
  }
  .set-person-content .form-tips{
    padding: 0 10px;
    box-sizing: border-box;
  }
  .set-person-content .set-person-header>p{
    flex: 1;
  }
  .set-person-content .set-person-header>span{
    width: 20px;
    text-align: end;
    font-size: 12px;
    transform: scale(0.8);
    color:#999999;
  }
  .set-person-content .set-persion-tips{
    height: 41px;
    line-height: 41px;
    padding: 0 12px;
    box-sizing: border-box;
  }
  .set-person-content .set-persion-btn{
    height: 48px;
    line-height: 48px;
    display: flex;
    text-align: center;
    font-size: 18px;
    font-family: 'PingFangSC-Regular';

  }
  .set-person-content .set-persion-btn>p:nth-child(1) {
    width: 106px;
    color: #666666;
    height: 48px;
    line-height: 48px;
    background: #FFFFFF;
  }
  .set-person-content .set-persion-allitems{
    flex: 1;
    background: #fff;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 10px;
  }
  .set-persion-btn>p:nth-child(2) {
    flex: 1;
    background: #CCCCCC;
    color: #fff;
  }
  .set-person-content .set-persion-btn .btn-active {
    background: #008FFE;
    height: 48px;
    line-height: 48px;
  }