.choice-persion-info {
    padding: 0 0 0 12px;
    background: #FFFFFF;
  }
  .choice-persion-info:nth-child(1){
    padding-top: 10px;
  }
   .choice-persion-info dl {
      width: 95%;
      margin: 0px;
      /* height: 64px; */
      /* line-height: 64px; */
      display: flex;
  }
   .choice-persion-info dl > dt{
    /* width: 80px;  */
    /* height: 64px; */
    line-height: 24px;
    display: flex;
    margin-right: 12px;
  }
   .persion-checkbox {
    width: 20px;
  }
  .choice-person-footer .am-checkbox-inner,
  .persion-checkbox .am-checkbox-inner {
    top: 2px;
    right: 5px;
    width: 15px;
    height: 15px;
  }
  .choice-person-footer .am-checkbox-inner::after,
  .persion-checkbox .am-checkbox-inner::after {
    top: 1.6px;
    right: 4.7px;
    border-width: 0 2px 2px 0;
    height: 8px;
  }
  .persion-checkbox .am-checkbox-disabled {
    opacity: 1;
  }
  .persion-checkbox .am-checkbox-disabled .am-checkbox-inner {
    background: #999;
  }
  .persion-checkbox .am-checkbox-disabled .am-checkbox-inner::after {
    width: 4.5px;
    height: 8px;
    border-color: white;
  }
  .choice-persion-info dl > dt .persion-header-image{
    width: 60px;
    height:64px;
    line-height: 64px;
    border-bottom: 1px solid #EEEEEE;
    text-align: center;
  }
  .choice-persion-info dl > dt .persion-header-image > img{
    width:40px;
    height: 40px;
    border-radius: 3px;
  }
  .choice-persion-info dl > dd{
    /* height: 64px; */
    border-bottom: 1px solid #EEEEEE;
    flex: 1;
    p{
        margin: 0;
    }
  }
  
  .choice-persion-info .persion-name{
    /* height: 32px; */
    line-height: 22px;
    font-size: 14px;
    color: #111111;
    word-break:break-all;
  }
  .choice-persion-info .persion-name>span{
    font-size: 12px;
    color: #999999;
  }
  
  .persion-name-free {
    font-size: 14px;
    color: #008FFE;
    float: right;
  }
  .choice-persion-info .persion-dept {
    height: 32px;
    line-height: 25px;
    font-size: 12px;
    color: #999999;
  }