.area-choice-content{
    height: 100vh;
    width: 100vw;
    background: rgb(240, 242, 245);
    padding-top: 20px;
    .title{
        display: flex;
        justify-content: space-between;
        padding-bottom: 18px;
        border-bottom: 1px solid #F5F5F5;
        .city-name{
            font-size: 19px;
            color: #000000;
            margin-left: 16px;
        }
        .choice-area{
            font-size: 13px;
            color: #0000FF;
            padding-top: 4px;
            margin-right: 23px;
        }
    }
    .areas{
        .area{
            height: 57px;
            border-bottom: 1px solid #F5F5F5;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 27px;
            padding-right: 23px;
            .area-name{
                font-size: 15px;
                color: #000000;
            }
            .radio{
                width: 18px;
                height: 18px;
            }
            .all-choice{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 36px;
                .text{
                    font-size: 11px;
                    color: #9B9B9B;
                }
            }
        }
    }
    .button{
        position: fixed;
        bottom: 26px;
        left: 23px;
        border-radius: 5px;
        width: 329px;
        height: 47px;
        background-color: #1D01FE;
        color: #F5F5F5;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}