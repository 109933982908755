.pc-filter-time-conetent{
    width: 100%;
    display: flex;
    div{
        flex: 1;
        padding: 0 10px;
        text-align: center;
        .ant-select{
            width: 100%;
        }
    }
}